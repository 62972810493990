/* eslint-disable-next-line */
@tailwind base;
/* eslint-disable-next-line */
@tailwind components;
/* eslint-disable-next-line */
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  font-family: "Inter", "Raleway", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefefbf;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3cbfae;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #05aabb;
}

.container {
  padding: 20px;
}

.form-control {
  text-align: left;
  justify-content: left;
}

.login {
  background-image: url("images/bg-login.jpg");
  background-color: #002d5d;
  background-repeat: no-repeat;
  background-size: contain;
  background-clip: padding-box;
  height: 100vh;
}

.acrylic {
  padding: 4em 6em;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin: 7px;
}

.login>.side-panel {
  background: rgba(255, 255, 255, 0.65);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.justify-end {
  align-items: flex-end;
  justify-content: end;
}

.side-panel-title,
.side-panel-subtitle {
  font-family: "Raleway", "Helvetica Neue", sans-serif;
}

.color-atac-primary,
.text-color-atac-primary {
  color: #002d5d;
}

.bg-color-atac-primary {
  color: #002d5d;
}

.color-atac-accent,
.text-color-atac-accent,
.nav-link.active,
.nav-link:hover {
  color: #3cbfae;
}

.custom-nav-link {
  cursor: pointer;
}

.bg-color-atac-accent {
  background-color: #3cbfae;
}

.image-button {
  cursor: pointer;
}

input[type="checkbox"] {
  cursor: pointer;
}